@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body,html
{
   background-color:  rgb(250, 250, 250);
   width: 100vw !important;
   margin: 0px !important;
   padding: 0px !important;
   
}