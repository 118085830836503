@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap);
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body,html
{
   background-color:  rgb(250, 250, 250);
   width: 100vw !important;
   margin: 0px !important;
   padding: 0px !important;
   
}
.Navbar {
  width: 100%;
  background-color: #fafafa;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 60px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.circle {
  height: 40px;
  width: 40px;
  overflow: hidden;
  background-color: #7f8487d9;
  border-radius: 50%;
}

.circle > img {
  width: 100%;
  height: 100%;
  display: block;
}

.logo {
  padding-right: 10px;
}

.CartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.CartContainer > a {
  padding: 0px 10px;
}

.Navbar a {
  font-size: 1.5rem;
  font-weight: 500;
  color: #1e7cbf;
  text-decoration: none;
  font-family: Poppins;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

@media (max-width: 780px) {
  .Navbar {
    width: 100%;
    background-color: #fafafa;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;
  }
}
/*# sourceMappingURL=Navbar.css.map */
.btnCartCount {
  height: 40px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 60px;
}

.btnCartCount > img {
  width: 30px;
  height: 30px;
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
}

.cartNotif {
  position: absolute;
  top: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fafafa;
  background-color: red;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: .6rem;
}
/*# sourceMappingURL=Cart.css.map */
.buttonAddRemove {
  width: 95%;
  margin: 0rem .5rem;
  text-align: center;
  font-family: Poppins;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 2px solid #1e7cbf;
  border-radius: 5px;
  cursor: pointer;
}

.buttonAddRemove-positive {
  width: 100%;
  height: 100%;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  color: #1e7cbf;
  font-weight: 500;
}

.side-btn {
  font-family: Poppins;
  background-color: #1e7cbf;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100%;
  color: #fafafa;
  font-size: 24px;
}
/*# sourceMappingURL=Button.css.map */
.Item {
  width: 400px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1rem;
  display: grid;
  text-align: start;
  align-items: center;
  justify-items: center;
  margin: 1rem;
}

.discount {
  position: absolute;
  font-family: Poppins;
  background-color: #000;
  color: #fafafa;
  font-size: 0.9rem;
  border-radius: 20px 15px 3px 20px;
  font-weight: 500;
  width: 500px;
  display: flex;
  top: 0%;
  left: 20%;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.cardImg {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.cardContainer {
  font-family: Poppins;
  margin: .7rem 0rem;
  padding: 1.2rem .6rem;
  display: flex;
  flex-direction: row;
}

.descContainer {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  justify-content: flex-start;
}

.Name {
  font-size: 1.1rem;
  font-weight: 600;
}

.Prices {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-size: 0.8rem;
  align-items: flex-end;
  display: flex;
  justify-content: end;
  text-align: end;
  flex-direction: row;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Prices > p {
  margin: 0px 3px;
}

.originalPrice {
  text-decoration: line-through;
  color: #7f8487d9;
  font-weight: 600;
}

.finalPrice {
  font-size: 1rem;
  font-weight: 600;
}

.descText {
  font-size: 0.8rem;
  padding: 0.5rem 0rem;
  color: #7f8487d9;
}

@media (max-width: 1100px) {
  .Item {
    margin: 0rem !important;
  }
}
/*# sourceMappingURL=Cards.css.map */
.headerContainer {
  height: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-family: Poppins;
  color: #3c424e;
  font-weight: 600 !important;
}

.headerDesign {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 1rem 0rem;
}

.starBackground {
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0.5rem;
  background-color: rgba(194, 224, 239, 0.531);
}

.parentContainer {
  width: 100vw;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mainContainer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  grid-row-gap: 5rem;
}

.discount {
  position: absolute;
  font-family: Poppins;
  background-color: #000;
  color: #fafafa;
  font-size: 0.9rem;
  border-radius: 20px 15px 3px 20px;
  font-weight: 500;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

@media (max-width: 1100px) {
  .mainContainer {
    padding: 0rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 780px) {
  .mainContainer {
    padding: 0rem;
    grid-template-columns: repeat(1, 1fr);
  }
}
/*# sourceMappingURL=Listings.css.map */
